<template>
  <div id="bacteriaSolution">
    <el-dialog
      :title="bacteriaSolutionFormTitle"
      width="1200px"
      :visible.sync="bacteriaSolutionDialogVisible"
      :close-on-click-modal="false"
      @close="bacteriaSolutionDialogClose"
    >
      <el-form
        ref="bacteriaSolutionFormRef"
        :model="bacteriaSolutionForm"
        :rules="bacteriaSolutionFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="工作代数" prop="generation">
              <el-input v-model="bacteriaSolutionForm.generation" placeholder="请输入工作代数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="菌种编号" prop="bacteriaNo">
              <el-input v-model="bacteriaSolutionForm.bacteriaNo" placeholder="请输入菌种编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="制备时间" prop="preparationTime">
              <el-date-picker v-model="bacteriaSolutionForm.preparationTime" placeholder="请选择日期" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="培养时间" prop="trainingTime">
              <el-date-picker v-model="bacteriaSolutionForm.trainingTime" placeholder="请选择日期" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="制备人" prop="preparer">
              <el-input v-model="bacteriaSolutionForm.preparer" placeholder="请输入制备人" clearable />
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="用途" prop="purpose">
              <el-input v-model="bacteriaSolutionForm.purpose" placeholder="请输入用途" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用日期" prop="useDate">
              <el-date-picker v-model="bacteriaSolutionForm.useDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用人" prop="user">
              <el-input v-model="bacteriaSolutionForm.user" placeholder="请输入使用人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销毁日期" prop="destructionDate">
              <el-date-picker v-model="bacteriaSolutionForm.destructionDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销毁原因" prop="destructionReason">
              <el-input v-model="bacteriaSolutionForm.destructionReason" placeholder="请输入销毁原因" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销毁人" prop="destroyer">
              <el-input v-model="bacteriaSolutionForm.destroyer" placeholder="请输入销毁人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="监销人" prop="supervisor">
              <el-input v-model="bacteriaSolutionForm.supervisor" placeholder="请输入监销人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="bacteriaSolutionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="bacteriaSolutionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="bacteriaSolutionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="generation" label="工作代数" />
      <el-table-column prop="bacteriaNo" label="菌种编号" />
      <el-table-column prop="preparationTime" label="制备时间" />
      <el-table-column prop="trainingTime" label="培养时间" />
      <el-table-column prop="preparer" label="制备人" />
      <el-table-column prop="purpose" label="用途" />
      <el-table-column prop="useDate" label="使用日期" />
      <el-table-column prop="user" label="使用人" />
      <el-table-column prop="destructionDate" label="销毁日期" />
      <el-table-column prop="destructionReason" label="销毁原因" />
      <el-table-column prop="destroyer" label="销毁人" />
      <el-table-column prop="supervisor" label="监销人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="bacteriaSolutionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addBacteriaSolution, deleteBacteriaSolution, updateBacteriaSolution, selectBacteriaSolutionInfo, selectBacteriaSolutionList } from '@/api/quality/bacteriaSolution'

export default {
  data () {
    return {
      bacteriaSolutionDialogVisible: false,
      bacteriaSolutionFormTitle: '',
      bacteriaSolutionForm: {
        id: '',
        generation: '',
        bacteriaNo: '',
        preparationTime: '',
        trainingTime: '',
        preparer: '',
        purpose: '',
        useDate: '',
        user: '',
        destructionDate: '',
        destructionReason: '',
        destroyer: '',
        supervisor: ''
      },
      bacteriaSolutionFormRules: {
        generation: [{ required: true, message: '工作代数不能为空', trigger: ['blur', 'change']}]
      },
      bacteriaSolutionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      }
    }
  },
  created () {
    selectBacteriaSolutionList(this.searchForm).then(res => {
      this.bacteriaSolutionPage = res
    })
  },
  methods: {
    bacteriaSolutionDialogClose () {
      this.$refs.bacteriaSolutionFormRef.resetFields()
      this.bacteriaSolutionForm.id = ''
    },
    bacteriaSolutionFormSubmit () {
      if (this.bacteriaSolutionFormTitle === '菌液制备、使用、销毁记录') {
        this.bacteriaSolutionDialogVisible = false
        return
      }
      this.$refs.bacteriaSolutionFormRef.validate(async valid => {
        if (valid) {
          if (this.bacteriaSolutionFormTitle === '新增菌液制备、使用、销毁记录') {
            await addBacteriaSolution(this.bacteriaSolutionForm)
          } else if (this.bacteriaSolutionFormTitle === '修改菌液制备、使用、销毁记录') {
            await updateBacteriaSolution(this.bacteriaSolutionForm)
          }
          this.bacteriaSolutionPage = await selectBacteriaSolutionList(this.searchForm)
          this.bacteriaSolutionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.bacteriaSolutionFormTitle = '新增菌液制备、使用、销毁记录'
      this.bacteriaSolutionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteBacteriaSolution(row.id)
        if (this.bacteriaSolutionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.bacteriaSolutionPage = await selectBacteriaSolutionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.bacteriaSolutionFormTitle = '修改菌液制备、使用、销毁记录'
      this.bacteriaSolutionDialogVisible = true
      this.selectBacteriaSolutionById(row.id)
    },
    handleInfo (index, row) {
      this.bacteriaSolutionFormTitle = '菌液制备、使用、销毁记录详情'
      this.bacteriaSolutionDialogVisible = true
      this.selectBacteriaSolutionById(row.id)
    },
    selectBacteriaSolutionById (id) {
      selectBacteriaSolutionInfo(id).then(res => {
        this.bacteriaSolutionForm.id = res.id
        this.bacteriaSolutionForm.generation = res.generation
        this.bacteriaSolutionForm.bacteriaNo = res.bacteriaNo
        this.bacteriaSolutionForm.preparationTime = res.preparationTime
        this.bacteriaSolutionForm.trainingTime = res.trainingTime
        this.bacteriaSolutionForm.preparer = res.preparer
        this.bacteriaSolutionForm.purpose = res.purpose
        this.bacteriaSolutionForm.useDate = res.useDate
        this.bacteriaSolutionForm.user = res.user
        this.bacteriaSolutionForm.destructionDate = res.destructionDate
        this.bacteriaSolutionForm.destructionReason = res.destructionReason
        this.bacteriaSolutionForm.destroyer = res.destroyer
        this.bacteriaSolutionForm.supervisor = res.supervisor
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectBacteriaSolutionList(this.searchForm).then(res => {
        this.bacteriaSolutionPage= res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectBacteriaSolutionList(this.searchForm).then(res => {
        this.bacteriaSolutionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectBacteriaSolutionList(this.searchForm).then(res => {
        this.bacteriaSolutionPage = res
      })
    }
  }
}
</script>

<style>
</style>
